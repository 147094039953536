import React from 'react'

import InstagramImage from 'images/instagram.svg'

import * as styles from './styles.module.scss'

const Footer = () => {
  return (
    <div className={styles.root}>
      <p>Like what you are seeing? See what we are up to:</p>

      <a
        className={styles.link}
        href="https://www.instagram.com/blackhoneynarrabeen"
        rel="noreferrer"
        target="_blank"
      >
        <InstagramImage height="40px" />

        <div className={styles.linkText}>
          <span>Follow us on Instagram!</span>
          <span>blackhoneynarrabeen</span>
        </div>
      </a>
    </div>
  )
}

export default Footer
